<script>
import Layout from '@layouts/main';
import ContentLoading from '@components/content-loading';
import DriverHeader from '@components/drivers/header';

import DRIVER_GET from '@graphql/driver/queries/get.gql';

export default {
  name: 'DriverHistory',
  page: {
    title: 'Motoristas',
  },
  components: {
    Layout,
    DriverHeader,
    ContentLoading,
  },
  apollo: {
    driver: {
      query: DRIVER_GET,
      variables() {
        return {
          id: this.$route.params.id
        };
      },
    },
  },
};
</script>

<template>
  <Layout>
    <driver-header :id="$route.params.id" />

    <div v-if="!$apollo.loading" class="animated fadeIn">
      alerts
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.driver-data {
    .card-title {
        font-size: 20px;
    }
    p {
        margin-bottom: 10px;

        &.cta-data {
            margin-top: 20px;
        }

        .detail {
            color: #666;
            font-size: 10px;
            margin-left: 5px;
        }
    }
}
</style>
