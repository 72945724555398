var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("driver-header", { attrs: { id: _vm.$route.params.id } }),
      !_vm.$apollo.loading
        ? _c("div", { staticClass: "animated fadeIn" }, [_vm._v(" alerts ")])
        : _c(
            "div",
            { staticClass: "animated fadeIn" },
            [_c("content-loading")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }